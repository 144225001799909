import React from 'react';

import HDonationCard from '@/core/components/DonationCard/HDonationCard';
import VDonationCard from '@/core/components/DonationCard/VDonationCard';
import type DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';

interface DonationCardProps {
  donation: DonationDomain;
  vertical?: true;
  horizontal?: true;
}

const DonationCard: React.FC<DonationCardProps> = ({ donation, vertical, horizontal }) => {
  if (vertical) return <VDonationCard donation={donation} />;

  if (horizontal) return <HDonationCard donation={donation} />;

  throw new Error('One of horizontal or vertical must be selected.');
};

export default DonationCard;
