import React from 'react';

import Avatar from '@/core/components/Avatar';
import DonationCardCity from '@/core/components/DonationCard/DonationCardCity';
import DonationCardDate from '@/core/components/DonationCard/DonationCardDate';
import DonationCardDistance from '@/core/components/DonationCard/DonationCardDistance';
import DonationCardPhoto from '@/core/components/DonationCard/DonationCardPhoto';
import DonationCardTitle from '@/core/components/DonationCard/DonationCardTitle';
import { useComponentsContext } from '@/core/lib/components/components.context';
import type DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { DonationState } from '@/core/types/donation';

interface VDonationCardProps {
  donation: DonationDomain;
}

const VDonationCard: React.FC<VDonationCardProps> = ({ donation }) => {
  const { Link } = useRouterContext();
  const { common } = AbstractRoute.useCommonParams();
  const { DonationCardState } = useComponentsContext();

  const { personae } = donation;
  const id = donation.getId();
  const title = donation.getTitle();
  const city = donation.getCityLabel();
  const distance = donation.getDistance();
  const photo = donation.getFirstPhoto(2);
  const state = donation.getState();
  const lastPublishedAt = donation.getLastPublishedAt();

  const user = donation.getUser();
  const { avatarUrl, username } = user.getPublicInfo();

  return (
    <div className="flex w-full flex-col gap-1 bg-bg-primary">
      <Link href={new Routes.PublicProfileRoute(user.uuid, common)}>
        <div className="inline-flex h-10 w-full items-center justify-start gap-1">
          <div className="flex size-10 items-start justify-start gap-[3.33px]">
            <Avatar avatarUrl={avatarUrl} size="40" />
          </div>
          {username && <p className="text-caption-primary line-clamp-1 shrink grow basis-0 break-all font-medium text-content-primary">{username}</p>}
        </div>
      </Link>

      <Link href={new Routes.DonationRoute(id, common)} className="flex flex-col gap-3">
        <div className="relative">
          <DonationCardPhoto photo={photo} title={title} height={196} width="full" />
          {state !== DonationState.PUBLISHED && (
            <div className="absolute bottom-0 w-full">
              <DonationCardState state={state} full personae={personae} />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <DonationCardTitle title={title} />

          <div className="flex flex-col gap-1">
            <DonationCardCity city={city} />

            <div className="flex flex-row gap-4">
              <DonationCardDistance distance={distance} />
              <DonationCardDate date={lastPublishedAt} />
            </div>
          </div>
        </div>

        <div className="h-2 w-full border-b border-b-stroke-tertiary" />
      </Link>
    </div>
  );
};

export default VDonationCard;
